import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface SetManigaUserModalState {
    isModalOpen: boolean
    isProcessing: boolean
}

const initialState: SetManigaUserModalState = {
    isModalOpen: false,
    isProcessing: false,
}

export const setManigaUserModalSlice = createSlice({
    name: 'set-maniga-user-modal',
    initialState,
    reducers: {
        toggleModal: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isModalOpen = payload;
        },
        setIsProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isProcessing = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    toggleModal,
    setIsProcessing,
    dispose
} = setManigaUserModalSlice.actions

export default setManigaUserModalSlice.reducer