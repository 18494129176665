import { combineReducers } from 'redux';
import matchDayListReducer from './match-day-list.slice'
import recentMatchDaysReducer from './recent-matchdays.slice'
import matchDayDetailsReducer from './match-day-details.slice'
import customTeamModalReducer from './custom-team-modal.slice'
import editMatchModalReducer from './edit-match-modal.slice'
import playerStatsReducer from './player-stats.slice'
import setManigaUserModalReducer from './set-maniga-user-modal.slice'

const matchDayRootReducer = combineReducers({
    list: matchDayListReducer,
    details: matchDayDetailsReducer,
    customTeamModal: customTeamModalReducer,
    recentList: recentMatchDaysReducer,
    editMatchModal: editMatchModalReducer,
    setManigaUserModal: setManigaUserModalReducer,
    stats: playerStatsReducer
});

export default matchDayRootReducer;